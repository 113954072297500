<template>
  <div class="rechangePage overflow  flex">
    <div class="rechangeLeft overflow">
      <li class=" textCenter baseTitle" :class="{ cur: current == index }" v-for="(item, index) in menuArray" :key="index"
        @click="changeMenu(index)">
        {{ item }}
      </li>
    </div>
    <div class="rechangeRight overflow">
      <keep-alive>
        <component v-bind:is="currentTabComponent" ></component>
      </keep-alive>
    </div>
  </div>
</template>
  <script>
import { Message } from 'element-ui';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      current: 0, //默认选中
      menuArray: ['积分充值', '充值记录'],//左侧导航
      currentTabComponent: 'integral',
    }
  },
  computed: {
    ...mapState(['isRechange']), //
  },
  activated(){
   this.current= 0;
   this.currentTabComponent='integral'
  },
deactivated(){

},
  components: {
    'integral': () => import('../components/integral'),
    'integrallist': () => import('../components/integrallist'),
  },
  methods: {//切换菜单
    changeMenu(index) {
     this.current = index;
      this.currentTabComponent = index == 0 ? 'integral' : 'integrallist'
    }
  }
}
</script>
  <style lang="less" scoped>
  .rechangePage {
    width: 100%;
    height: 100%;
  }
  
  .rechangeLeft {
    width: 7%;
    background: #DCDCDC;
    height: 100%;
    border: 1px solid #DCDCDC;
  
    li {
      width: 100%;
      line-height: 26px;
      cursor: pointer;
    }
  
    .cur {
      background: #fff;
    }
  
  }
  
  .rechangeRight {
        width: 93%;
        height: 100%;
        padding-left: 6px;
      }
    
  </style>
